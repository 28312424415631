import { Injectable } from '@angular/core';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';


enum NotificationType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info'
} 
@Injectable()
export class PopupService {

  private readonly notificationDuration: number = 2000;


  constructor(
    private readonly kendoNotificationService: NotificationService,
  ) {}

  

  error(message:string, icon?: boolean, cssClass?: string): void {
    this.showNotification(NotificationType.Error, message, icon, cssClass);
  }

  success(message:string, icon?: boolean, cssClass?: string): void {
    this.showNotification(NotificationType.Success, message, icon, cssClass);
  }

  warning(message:string, icon?: boolean, cssClass?: string): void {
    this.showNotification(NotificationType.Warning, message, icon, cssClass);
  }

  showNotification(
    type: NotificationType,
    message:string,
    icon?: boolean,
    cssClass?: string
  ): void {
    this.showCustomNotification(
      message,
      type,
      icon,
      cssClass
    );
  }

  showCustomNotification(
    message: string,
    type: NotificationType,
    icon?: boolean,
    cssClass?: string
  ): void {
    this.kendoNotificationService.show({
      content: message,
      cssClass: `a2b-toast-notification ${cssClass}`,
      animation: { type: 'slide', duration: 400 },
      position: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      hideAfter: 2000,
      type: { style: type, icon: icon }
    });
  }

  hideNotification(): void {
    // TODO
  }
}
